<template>
    <div class="container-fluid">
        <ul class="nav nav-tabs h9 ">
            <li class="nav-item" >
                <button class="nav-link" @click="tab=1" :class="tab==1 ? 'nav-link active fw-bold' : ''">Stock</button>
            </li>
            <li class="nav nav-item h9 ">
                <button class="nav-link" @click="tab=2" :class="tab==2 ? 'nav-link active fw-bold' : ''">Marketing</button>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade show active mt-3" v-if="tab==1">
                <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                    <div class="card border-0">
                        <div class="accordion mb-3">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#filter">
                                    <p class="h9 fw-bold m-0">Filter</p>
                                  </button>
                                </h2>
                                <div id="filter" class="accordion-collapse collapse show">
                                  <div class="accordion-body">
                                    <form class="container" id="frmStock" v-on:submit.prevent="showStock" autocomplete="off">
                                        <div class="row align-items-end">
                                            <div class="col-md-10 row">
                                                <div class="col-md-4">
                                                    <label class="form-label h9 fw-bold">Period</label>
                                                    <input v-model="stock.periode" class="form-control" type="text" placeholder="periode" readonly>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-label h9 fw-bold">Location</label>
                                                    <select v-model="stock.location" class="form-select" style="width: 100%;" required>
                                                        <option value="" disabled selected>Pilih Location</option>
                                                        <option value="SUB">Surabaya</option>
                                                        <option value="SRG">Semarang</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-label h9 fw-bold">Warehouse</label>
                                                    <model-list-select
                                                        :list="gudangs"
                                                        class="form-select"
                                                        option-value="LocationID"
                                                        option-text="Description"
                                                        v-model="stock.gudang"
                                                        placeholder="gudang">
                                                    </model-list-select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <button type="submit" class="btn btn-primary" form="frmStock"><i class="fa fa-search"></i>&nbsp; Show</button>
                                            </div>  
                                        </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <table class="table mb-0 h9 table-hover table-strip table-bordered" id="recstock">
                            <thead>
                                <tr class="h9">
                                    <th class="w3-border-right">ID</th>
                                    <th class="w3-border-right">Item</th>
                                    <th class="w3-border-right">qty</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active mt-3" v-if="tab==2">
                <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                    <div class="card border-0">
                        <div class="accordion mb-3">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <p class="h9 fw-bold m-0">Filter</p>
                                  </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                    <form class="container" id="frmMarketing" v-on:submit.prevent="showStockMarketing" autocomplete="off">
                                        <div class="row align-items-end justify-content-center">
                                        <div class="col-md-5">
                                            <label class="form-label h9 fw-bold">Period</label>
                                            <input v-model="marketing.periode" class="form-control" type="text" placeholder="periode" readonly>
                                        </div>
                                        <div class="col-md-2" >
                                            <button type="submit" class="btn btn-primary" form="frmMarketing"><i class="fa fa-search"></i>&nbsp; Show</button>
                                        </div>  
                                        </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <table class="table mb-0 h9 table-hover table-strip table-bordered" id="recmarketing">
                            <thead>
                                <tr class="h9">
                                    <th class="w3-border-right">ID</th>
                                    <th class="w3-border-right">Item</th>
                                    <th class="w3-border-right">Gudang</th>
                                    <th class="w3-border-right">Qty</th>
                                    <th class="w3-border-right">SO</th>
                                    <th class="w3-border-right">PO</th>
                                    <th class="w3-border-right">Net Stock</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
// import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'ItemStock',
    components: {
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            stock: {
                periode : '',
                location : '',
                gudang : '',
                pfunction : 'showstock'
            },
            stocks : [],
            marketing: {
                periode : '',
                pfunction : 'showstockmarketing'
            },
            marketings: [],
            gudangs: [],
            tab : 1
        }
    },
    methods : {
        showStock(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("sybase/ApiSBItemBalance.php",self.stock).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.stocks = response.data.rec;
                $('#recstock').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.stocks,
                    columns: [
                        {"data": "ItemID"},
                        {"data": "name"},
                        {"data": "qty"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',                        
                        }
                    ],
                });
            });
            event.preventDefault();
            return false;
        },
        showStockMarketing(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("sybase/ApiSBItemBalance.php",self.marketing).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.marketings = response.data.rec;
                $('#recmarketing').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.marketings,
                    columns: [
                        {"data": "ItemID"},
                        {"data": "ItemName"},
                        {"data": "GudangName"},
                        {"data": "qtyGudang"},
                        {"data": "soTotal"},
                        {"data": "poTotal"},
                        {"data": "NetStock"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',                        
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        setInit() {
            let self = this;
            axios.post("sybase/ApiSBPeriode.php",{
                pfunction : 'getperiode'
            }).then(function (response) {
                self.stock.periode = response.data.rec[0]['PeriodID'];
                self.marketing.periode = response.data.rec[0]['PeriodID'];
            });
            axios.post("sybase/ApiSBGudang.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.gudangs = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
        'tab': function(dt){
            if (dt == 1) {
                $('#recmarketing').DataTable().destroy();
            }else{        
                $('#recstock').DataTable().destroy();
            }
        }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>